import { useCookie } from "#app";
import { useProductsStore } from "~/stores/products";
import { useMainStore } from "~/stores/main";
import { usePageStore } from "~/stores/page";

export const useSurveyStore = defineStore('survey', {
  state: () => ({
    isOpen: false,
    canShowSurvey: true,
    isPaged: true,
    timeout: 2000,
    submitSuccess: false,
    networkError: false,
    submitting: false,
    currentType: '',
    all: {
      reading_habits_2022: {
        type: 'reading_habits_2022',
        answers: {
          q1: '',
          q2: '',
          q3: 'none',
          q4: 'none',
        },
        title: 'Quick reading habits survey',
        description: '',
        questions: [
          {
            key: 'q1',
            numeric: true,
            placeholder: 'Age:',
            text: 'How old are you?',
          }, {
            key: 'q2',
            text: 'What best describes your reading habits for print books and ebooks? (no audiobooks) <br> In the past 12-months:',
            answers: [
              {
                value: 'did_not',
                text: 'I didn\'t read a book',
              },
              {
                value: 'some',
                text: 'I read some of at least one book',
              },
              {
                value: 'majority',
                text: 'I read the majority of at least one book',
              },
              {
                value: 'one_or_more',
                text: 'I finished one or more books',
              },
            ],
          }, {
            key: 'q3',
            numeric: true,
            placeholder: 'Number:',
            show_conditions: [
              {
                question: 'q2',
                values: ['', 'one_or_more'],
              },
            ],
            text: 'How many full print books or ebooks did you finish reading in the past 12-months?',
          }, {
            key: 'q4',
            show_conditions: [
              {
                question: 'q2',
                values: ['did_not', 'some', 'majority'],
              },
            ],
            text: 'How many years has it been since you read a FULL print book or ebook?',
            answers: [
              {
                value: '10_plus',
                text: '10+ years',
              },
              {
                value: '5_10',
                text: '5-10 years',
              },
              {
                value: '3_5',
                text: '3-5 years',
              },
              {
                value: '2_3',
                text: '2-3 years',
              },
              {
                value: '1_2',
                text: '1-2 years',
              },
            ],
          },
        ],
      },
      quick_reading_habits_2022: {
        type: 'quick_reading_habits_2022',
        answers: {
          q1: '',
          q2: '',
          q3: '',
          q4: '',
        },
        title: 'Quick reading habits survey',
        description: '',
        questions: [
          {
            key: 'q1',
            numeric: true,
            placeholder: 'Age:',
            text: 'How old are you?',
          }, {
            key: 'q2',
            text: 'What is your sex?',
            answers: [
              {
                value: 'male',
                text: 'Male',
              },
              {
                value: 'female',
                text: 'Female',
              },
            ],
          }, {
            key: 'q3',
            text: 'When was the last time you read a FULL print book or ebook?',
            answers: [
              {
                value: 'last_year',
                text: 'Within the past 12 months',
              },
              {
                type: 'numeric',
                prefix: 'Over',
                suffix: 'year(s) ago',
                value: '',
              },
            ],
          }, {
            key: 'q4',
            text: 'Will you read a FULL print book or ebook in the next 12-months?',
            answers: [
              {
                value: 'no',
                text: 'No',
              },
              {
                value: 'not_likely',
                text: 'Not likely',
              },
              {
                value: 'likely',
                text: 'Likely',
              },
              {
                value: 'definitely',
                text: 'Definitely',
              },
            ],
          },
        ],
      },
      // what_would_stop_you_from_using_runrepeat_with_offers_dec_2021: {
      //   type: 'what_would_stop_you_from_using_runrepeat_with_offers_dec_2021',
      //   answers: {
      //     q1: '',
      //   },
      //   title: '',
      //   description: '',
      //   questions: [
      //     {
      //       key: 'q1',
      //       placeholder: 'Type your answer here',
      //       text: 'What would stop you from using RunRepeat for your next purchase?',
      //       // finishSurvey: true,
      //     },
      //   ],
      // },

      // how_many_pairs_running_nov_2021: {
      //   type: 'how_many_pairs_nov_2021',
      //   answers: {
      //     q1: '',
      //     q2: '',
      //   },
      //   title: '',
      //   description: '',
      //   questions: [
      //     {
      //       key: 'q1',
      //       text: 'How often do you run?',
      //       answers: [
      //         {
      //           value: 'less_1_time_week',
      //           text: 'Less than 1 time/week',
      //         },
      //         {
      //           value: '1_time_week',
      //           text: '1 time/week',
      //         },
      //         {
      //           value: '2_times_week',
      //           text: '2 times/week',
      //         },
      //         {
      //           value: '3_times_week',
      //           text: '3 times/week',
      //         },
      //         {
      //           value: '4_times_week',
      //           text: '4 times/week',
      //         },
      //         {
      //           value: '5_times_week',
      //           text: '5 times/week',
      //         },

      //         {
      //           value: '6_times_week',
      //           text: '6 times/week',
      //         },
      //         {
      //           value: '7_times_week_or_more',
      //           text: '7 times/week or more',
      //         },
      //       ],
      //     },
      //     {
      //       key: 'q2',
      //       text: 'How many pairs of running shoes do you buy in a year?',
      //       finishSurvey: true,
      //       answers: [
      //         { value: 'less_1', text: 'less than 1' },
      //         { value: '1', text: '1' },
      //         { value: '2', text: '2' },
      //         { value: '3_or_more', text: '3 or more' },
      //       ],
      //     },
      //   ],
      // },
      // how_many_pairs_basketball_nov_2021: {
      //   type: 'how_many_pairs_basketball_nov_2021',
      //   answers: {
      //     q1: '',
      //     q2: '',
      //   },
      //   title: '',
      //   description: '',
      //   questions: [
      //     {
      //       key: 'q1',
      //       text: 'How often do you play basketball?',
      //       answers: [
      //         {
      //           value: 'less_1_time_week',
      //           text: 'Less than 1 time/week',
      //         },
      //         { value: '1_time_week', text: '1 time/week' },
      //         { value: '2_time_week', text: '2 time/week' },
      //         { value: '3_time_week', text: '3 time/week' },
      //         { value: '4_time_week', text: '4 time/week' },
      //         { value: '5_time_week', text: '5 time/week' },
      //         { value: '6_time_week', text: '6 time/week' },
      //         { value: '7_time_week_or_more', text: '7 times/week or more' },
      //       ],
      //     },
      //     {
      //       key: 'q2',
      //       text: 'How many pairs of basketball shoes do you buy in a year?',
      //       finishSurvey: true,
      //       answers: [
      //         { value: 'less_1', text: 'less than 1' },
      //         { value: '1', text: '1' },
      //         { value: '2', text: '2' },
      //         { value: '3_or_more', text: '3 or more' },
      //       ],
      //     },
      //   ],
      // },
      // how_many_pairs_hiking_nov_2021: {
      //   type: 'how_many_pairs_hiking_nov_2021',
      //   answers: {
      //     q1: '',
      //     q2: '',
      //   },
      //   title: '',
      //   description: '',
      //   questions: [
      //     {
      //       key: 'q1',
      //       text: 'How often do you go hiking?',
      //       answers: [
      //         {
      //           value: 'less_1_day_month',
      //           text: 'Less than 1 day/month',
      //         },
      //         { value: '1_day_month', text: '1 day/month' },
      //         { value: '2_days_month', text: '2 days/month' },
      //         { value: '3_days_month', text: '3 days/month' },
      //         { value: '4_days_month', text: '4 days/month' },
      //         { value: '5_days_month', text: '5 days/month' },
      //         { value: '6_days_month', text: '6 days/month' },
      //         { value: '7_days_month_or_more', text: '7 days/month or more' },
      //       ],
      //     },
      //     {
      //       key: 'q2',
      //       text: 'How many pairs of hiking footwear do you buy in a year?',
      //       finishSurvey: true,
      //       answers: [
      //         { value: 'less_1', text: 'less than 1' },
      //         { value: '1', text: '1' },
      //         { value: '2', text: '2' },
      //         { value: '3_or_more', text: '3 or more' },
      //       ],
      //     },
      //   ],
      // },
      // how_many_pairs_sneakers_nov_2021: {
      //   type: 'how_many_pairs_sneakers_nov_2021',
      //   answers: {
      //     q1: '',
      //   },
      //   title: '',
      //   description: '',
      //   questions: [
      //     {
      //       key: 'q1',
      //       text: 'How many pairs of sneakers do you buy in a year?',
      //       finishSurvey: true,
      //       answers: [
      //         { value: 'less_1', text: 'less than 1' },
      //         { value: '1', text: '1' },
      //         { value: '2', text: '2' },
      //         { value: '3_or_more', text: '3 or more' },
      //       ],
      //     },
      //   ],
      // },

      // whats_holding_back_from_purchase_nov_2021: {
      //   type: 'whats_holding_back_from_purchase_nov_2021',
      //   answers: {
      //     q1: '',
      //   },
      //   title: '',
      //   description: '',
      //   questions: [
      //     {
      //       key: 'q1',
      //       text: 'What\'s holding you back from making a purchase today?',
      //       placeholder: 'Type your answer here',
      //       answers: [],
      //     },
      //   ],
      // },
      // what_did_you_come_to_do_nov_2021: {
      //   type: 'what_did_you_come_to_do_nov_2021',
      //   answers: {
      //     q1: '',
      //   },
      //   title: '',
      //   description: '',
      //   questions: [
      //     {
      //       key: 'q1',
      //       text: 'Hi! What did you come to our website to do?',
      //       placeholder: 'Type your answer here',
      //       answers: [],
      //     },
      //   ],
      // },
      did_you_find_what_you_need_jan_2022_final: {
        type: 'did_you_find_what_you_need_jan_2022_final',
        answers: {
          q1: '',
          q2: '',
        },
        title: '',
        description: '',
        questions: [
          {
            key: 'q1',
            text: 'Did you find the information you needed on this page?',
            answers: [
              {
                value: 'yes',
                text: 'Yes',
              },
              {
                value: 'no',
                text: 'No',
              },
            ],
          },
          {
            key: 'q2',
            text: '',
            placeholder: 'Type your answer here',
            answers: [],
          },
        ],
      },
      // running_harassment: {
      //   type: 'running_harassment',
      //   answers: {
      //     q1: '',
      //     q2: '',
      //     q3: '',
      //     q4: '',
      //   },
      //   title: '4 questions about harassment while running',
      //   description: '',
      //   questions: [
      //     {
      //       key: 'q1',
      //       text: 'How often do you run?',
      //       answers: [
      //         {
      //           value: 'times_1_2',
      //           text: '1-2 times per week',
      //         },
      //         {
      //           value: 'times_3_plus',
      //           text: '3+ times per week',
      //         },
      //         {
      //           value: 'never',
      //           text: 'Never',
      //           finishSurvey: true,
      //         },
      //       ],
      //     },
      //     {
      //       key: 'q2',
      //       text: 'You are:',
      //       answers: [
      //         {
      //           value: 'male',
      //           text: 'Male',
      //         },
      //         {
      //           value: 'female',
      //           text: 'Female',
      //         },
      //       ],
      //     },
      //     {
      //       key: 'q3',
      //       text: 'Have you experienced harassment while running? (check all that apply)',
      //       multiple: true,
      //       answers: [
      //         {
      //           value: 'attention',
      //           text: 'Yes, in the form of unwanted attention',
      //         },
      //         {
      //           value: 'comments',
      //           text: 'Yes, in the form of unwanted comments',
      //         },
      //         {
      //           value: 'contact',
      //           text: 'Yes, in the form of unwanted contact',
      //         },
      //         {
      //           value: 'happening',
      //           text: 'I know of it happening to runners in my area',
      //         },
      //         {
      //           value: 'none',
      //           text: 'None of the above',
      //         },
      //       ]
      //     },
      //     {
      //       key: 'q4',
      //       text: 'Did these experiences lead you to (check all that apply):',
      //       multiple: true,
      //       answers: [
      //         {
      //           value: 'stop_or_less',
      //           text: 'Stop running or run less often',
      //         },
      //         {
      //           value: 'change_route',
      //           text: 'Change running route or when you run',
      //         },
      //         {
      //           value: 'change_clothing',
      //           text: 'Change clothing',
      //         },
      //         {
      //           value: 'stop_solo',
      //           text: 'Stop running solo',
      //         },
      //         {
      //           value: 'start_inside',
      //           text: 'Start running inside (on a treadmill)',
      //         },
      //         {
      //           value: 'protection',
      //           text: 'Started running with protection (Weapon or device for self defense)',
      //         },
      //         {
      //           value: 'none',
      //           text: 'None of the above',
      //         },
      //       ]
      //     },
      //   ],
      // },
      // gym_harassment: {
      //   type: 'gym_harassment',
      //   answers: {
      //     q1: '',
      //     q2: '',
      //     q3: '',
      //   },
      //   title: 'Gym Harassment Survey (3 questions)',
      //   description: '',
      //   questions: [
      //     {
      //       key: 'q1',
      //       text: 'Have you been a gym member in the past 3 years and what is your sex? ',
      //       answers: [
      //         {
      //           value: 'male',
      //           text: 'Yes and I am a male',
      //         },
      //         {
      //           value: 'female',
      //           text: 'Yes and I am a female',
      //         },
      //         {
      //           value: 'no',
      //           text: 'no',
      //         },
      //       ],
      //     },
      //     {
      //       key: 'q2',
      //       text: 'Have you experienced harassment while at the gym? (check all that apply)',
      //       multiple: true,
      //       answers: [
      //         {
      //           value: 'attention',
      //           text: 'Yes, in the form of unwanted attention',
      //         },
      //         {
      //           value: 'comments',
      //           text: 'Yes, in the form of unwanted comments',
      //         },
      //         {
      //           value: 'contact',
      //           text: 'Yes, in the form of unwanted contact',
      //         },
      //         {
      //           value: 'witnessed',
      //           text: 'I have witnessed harassment at my gym',
      //         },
      //         {
      //           value: 'heard',
      //           text: 'I have heard of harassment happening at my gym',
      //         },
      //         {
      //           value: 'none',
      //           text: 'None of the above',
      //         },
      //       ]
      //     },
      //     {
      //       key: 'q3',
      //       text: 'These experiences led me to (check all that apply):',
      //       multiple: true,
      //       answers: [
      //         {
      //           value: 'stop_all',
      //           text: 'Stop going to gyms altogether',
      //         },
      //         {
      //           value: 'switch',
      //           text: 'Switch to a different gym',
      //         },
      //         {
      //           value: 'uncomfortable',
      //           text: 'Feel uncomfortable or unsafe at the gym',
      //         },
      //         {
      //           value: 'avoid',
      //           text: 'Change gym routine or avoid certain areas',
      //         },
      //         {
      //           value: 'change_wear',
      //           text: 'Change what I wear to the gym',
      //         },
      //         {
      //           value: 'report',
      //           text: 'Report the incident to management',
      //         },
      //         {
      //           value: 'none',
      //           text: 'None of the above',
      //         },
      //       ]
      //     },
      //   ],
      // },
      // sports_idol: {
      //   type: 'sports_idol',
      //   answers: {
      //     q1: '',
      //     q2: '',
      //     q3: '',
      //     q4: '',
      //   },
      //   title: 'Who is your sports idol? Help us understand who inspires people.',
      //   description: '',
      //   questions: [
      //     {
      //       key: 'q1',
      //       text: 'My sports idol is',
      //       answers: [
      //         {
      //           value: '',
      //           text: '',
      //         },
      //         {
      //           value: 'never_had',
      //           text: 'I’ve never had a sports idol.',
      //         },
      //       ],
      //     },
      //     {
      //       key: 'q2',
      //       text: 'My gender is',
      //       answers: [
      //         {
      //           value: 'female',
      //           text: 'Female',
      //         },
      //         {
      //           value: 'male',
      //           text: 'Male',
      //         },
      //         {
      //           value: 'other',
      //           text: 'Other',
      //         },
      //       ]
      //     },
      //     {
      //       key: 'q3',
      //       text: 'My age is',
      //       answers: [
      //         {
      //           value: '18',
      //           text: '<18',
      //         },
      //         {
      //           value: '24',
      //           text: '18-24',
      //         },
      //         {
      //           value: '39',
      //           text: '25-39',
      //         },
      //         {
      //           value: '59',
      //           text: '40-59',
      //         },
      //         {
      //           value: '60',
      //           text: '60+',
      //         },
      //       ]
      //     },
      //     {
      //       key: 'q4',
      //       text: 'I identify as',
      //       answers: [
      //         {
      //           value: 'white',
      //           text: 'White',
      //         },
      //         {
      //           value: 'latino',
      //           text: 'Hispanic or Latino',
      //         },
      //         {
      //           value: 'asian',
      //           text: 'Asian',
      //         },
      //         {
      //           value: 'black',
      //           text: 'Black or African-American',
      //         },
      //         {
      //           value: 'multi',
      //           text: 'Multiracial',
      //         },
      //         {
      //           value: 'other',
      //           text: 'Other',
      //         },
      //       ]
      //     },
      //   ],
      // },
      // boycott_esl: {
      //   type: 'boycott_esl',
      //   answers: {
      //     q1: '',
      //     q2: '',
      //   },
      //   title: 'Do you agree with the following statement about European Super League (ESL)?',
      //   description: '',
      //   questions: [
      //     {
      //       key: 'q1',
      //       text: 'I will boycott European Super League',
      //       answers: [
      //         {
      //           value: 'yes_boycott_entirely',
      //           text: 'Yes, I will boycott entirely',
      //         },
      //         {
      //           value: 'yes_will_not_watch',
      //           text: 'Yes, I will not watch ESL in-person nor on TV',
      //         },
      //         {
      //           value: 'yes_boycott_companies',
      //           text: 'Yes, I will boycott companies that support ESL',
      //         },
      //         {
      //           value: 'yes_considering',
      //           text: 'Yes, I’m considering boycotting',
      //         },
      //         {
      //           value: 'no',
      //           text: 'No, I won’t boycott',
      //         },
      //         {
      //           value: 'dont_know',
      //           text: 'I don’t know',
      //         },
      //       ],
      //     },
      //     {
      //       key: 'q2',
      //       text: 'Do you currently support one of the 12 ESL founding teams?',
      //       answers: [
      //         {
      //           value: 'yes',
      //           text: 'Yes',
      //         },
      //         {
      //           value: 'no',
      //           text: 'No',
      //         },
      //         {
      //           value: 'dont_like',
      //           text: 'I don’t like football',
      //         },
      //       ]
      //     },
      //   ],
      // },
      // return_to_work: {
      //   type: 'return_to_work',
      //   answers: {
      //     q1: '',
      //     q2: '',
      //     q3: '',
      //   },
      //   title: 'Returning to work and exercise (3 questions)',
      //   description: '',
      //   questions: [
      //     {
      //       key: 'q1',
      //       text: 'At any point during the pandemic were you working remotely?',
      //       answers: [
      //         {
      //           value: 'yes_still_working',
      //           text: 'Yes and I’m still working remotely',
      //         },
      //         {
      //           value: 'yes_part_time_office',
      //           text: 'Yes but have gone back to the office part-time',
      //         },
      //         {
      //           value: 'yes_full_time_office',
      //           text: 'Yes but have gone back to the office full-time',
      //         },
      //         {
      //           value: 'no',
      //           text: 'No, I did not work remotely',
      //         },
      //       ],
      //     },
      //     {
      //       key: 'q2',
      //       text: 'While working remotely, how many days per week were you exercising on average?',
      //       answers: [
      //         {
      //           value: '0',
      //           text: '0',
      //         },
      //         {
      //           value: '1',
      //           text: '1',
      //         },
      //         {
      //           value: '2',
      //           text: '2',
      //         },
      //         {
      //           value: '3',
      //           text: '3',
      //         },
      //         {
      //           value: '4',
      //           text: '4',
      //         },
      //         {
      //           value: '5',
      //           text: '5',
      //         },
      //         {
      //           value: '6',
      //           text: '6',
      //         },
      //         {
      //           value: '7',
      //           text: '7',
      //         },
      //       ]
      //     },
      //     {
      //       key: 'q3',
      //       text: 'Since returning to the office, how many days per week are you exercising on average?',
      //       answers: [
      //         {
      //           value: '0',
      //           text: '0',
      //         },
      //         {
      //           value: '1',
      //           text: '1',
      //         },
      //         {
      //           value: '2',
      //           text: '2',
      //         },
      //         {
      //           value: '3',
      //           text: '3',
      //         },
      //         {
      //           value: '4',
      //           text: '4',
      //         },
      //         {
      //           value: '5',
      //           text: '5',
      //         },
      //         {
      //           value: '6',
      //           text: '6',
      //         },
      //         {
      //           value: '7',
      //           text: '7',
      //         },
      //       ]
      //     },
      //   ],
      // },
      // example: {
      //   type: 'example',
      //   answers: {
      //     q1: '',
      //   },
      //   title: '',
      //   description: '',
      //   questions: [
      //     {
      //       key: 'q1',
      //       text: 'Why?',
      //       answers: [
      //         {
      //           value: 'yes',
      //           text: 'Yes',
      //         },
      //         {
      //           value: 'no',
      //           text: 'No',
      //         },
      //       ],
      //     },
      //   ],
      // },
    },
  }),

  getters: {
    questions(state) {
      return state.all[state.currentType] ? state.all[state.currentType]?.questions : [];
    },
    answers(state) {
      return state.all[state.currentType] ? state.all[state.currentType]?.answers : {};
    },
    description(state) {
      return state.all[state.currentType] ? state.all[state.currentType]?.description : '';
    },
    title(state) {
      return state.all[state.currentType] ? state.all[state.currentType]?.title : '';
    },
  },

  actions: {
    setAnswer({ answer, key, type = '' }) {
      const surveyTypes = Object.keys(this.all);
      if (surveyTypes.length) {
        // if no type, take the first one.
        if (!type) {
          type = this.currentType;
        }
        const currentType = surveyTypes.indexOf(type) > -1 ? type : surveyTypes[0];
        this.all[currentType].answers[key] = answer;
      }
    },
    enableSurvey() {
      this.canShowSurvey = true;
    },
    disableSurvey() {
      this.canShowSurvey = false;
    },
    open({ type = '', timeout = null }) {
      if (!this.canShowSurvey) {
        return;
      }
      setTimeout(() => {
        const cookieName = 'survey_shown';

        const cookie = useCookie(cookieName, {
          maxAge: 365 * 24 * 3600,
          path: '/',
        });

        if (cookie && cookie.value) {
          this.disableSurvey();
          return;
        }

        this.currentType = type;
        this.isOpen = true;

        cookie.value = 'true';
      }, timeout || this.timeout);

      useNuxtApp().$gaEvents.surveyShown(type);
    },
    async submitSurvey() {
      this.submitting = true;
      const products = useProductsStore();
      const main = useMainStore();
      const page = usePageStore();

      $api(`/api/visitor/submit-survey`, {
        method: 'POST',
        params: {
          type: this.currentType,
          data: {
            answers: this.answers,
            gender: main.gender,
            category: page.ga.category,
            primary_fact:
              products.current && products.current.primary_fact_value
                ? products.current.primary_fact_value.name
                : '',
          },
          country: main.countryCode,
          url: window && window.location ? window.location.pathname : '',
        }
      })
        .then(response => {
          this.submitSuccess = true;
          this.networkError = false;
          this.submitting = false;

          setTimeout(() => {
            this.isOpen = false;
            this.canShowSurvey = false;
          }, 3000);

          useNuxtApp().$gaEvents.surveySubmitted(this.currentType);
        })
        .catch(error => {
          this.networkError = true;
        });
    },
  },
})
